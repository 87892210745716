// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import "@angular/material/theming";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

$custom-typography: mat-typography-config(
  $font-family: "Roboto, monospace",
  $headline: mat-typography-level(32px, 48px, 700),
  $body-1: mat-typography-level(16px, 24px, 500),
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$portal-primary: mat-palette($mat-red, 800);
$portal-accent: mat-palette($mat-indigo, A200, A100, A400);

// The warn palette is optional (defaults to red).
$portal-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$portal-theme: mat-light-theme(
  (
    color: (
      primary: $portal-primary,
      accent: $portal-accent,
      warn: $portal-warn,
    ),
  )
);

@font-face {
  font-family: "Avenir";
  src: url("/assets/fonts/Avenir Regular.ttf");
  src: url("/assets/fonts/Avenir Black.ttf");
  src: url("/assets/fonts/Avenir Heavy.ttf");
  src: url("/assets/fonts/Avenir Light.ttf");
  src: url("/assets/fonts/Avenir Book.ttf");
}

@font-face {
  font-family: "DTL Prokyon T";
  src: url("https://coolecontent.com/fonts/DTLProkyonT-Bold.ttf");
  src: url("https://coolecontent.com/fonts/DTLProkyonT-Regular.ttf");
}

@font-face {
  font-family: "Bonfire";
  src: url("https://coolecontent.com/fonts/Bonfire_rough.otf");
  src: url("https://coolecontent.com/fonts/Bonfire_regular.otf");
}

@font-face {
  font-family: "Booster Next FY";
  src: url("https://coolecontent.com/fonts/Fontyou - Booster Next FY Regular.ttf");
  src: url("https://coolecontent.com/fonts/BoosterNextFY-Bold.ttf");
  src: url("https://coolecontent.com/fonts/BoosterNextFY-Light.ttf");
}

/* Antipasto Pro */
@font-face {
  font-family: "Antipasto Pro";
  src: url("/assets/fonts/Antipasto-Pro/Antipasto-Pro-Regular.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Antipasto Pro";
  src: url("/assets/fonts/Antipasto-Pro/Antipasto-Pro-Bold.ttf");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Antipasto Pro";
  src: url("/assets/fonts/Antipasto-Pro/Antipasto-Pro-Light.ttf");
  font-weight: 300;
  font-style: normal;
}

/* FilsonPro */
@font-face {
  font-family: "Filson Pro";
  src: url("/assets/fonts/FilsonPro/FilsonPro-Regular.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Filson Pro";
  src: url("/assets/fonts/FilsonPro/FilsonPro-Bold.ttf");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Filson Pro";
  src: url("/assets/fonts/FilsonPro/FilsonPro-Medium.ttf");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Filson Pro";
  src: url("/assets/fonts/FilsonPro/FilsonPro-Light.ttf");
  font-weight: 300;
  font-style: normal;
}

/* Nove */
@font-face {
  font-family: "Nove";
  src: url("/assets/fonts/Nove/Nove.ttf");
  font-weight: normal;
  font-style: normal;
}

/* Segoe UI */
@font-face {
  font-family: "Segoe UI";
  src: url("/assets/fonts/Segoe UI/Segoe UI.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Segoe UI";
  src: url("/assets/fonts/Segoe UI/Segoe UI Bold.ttf");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Segoe UI";
  src: url("/assets/fonts/Segoe UI/Segoe UI Italic.ttf");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Segoe UI";
  src: url("/assets/fonts/Segoe UI/Segoe UI Bold Italic.ttf");
  font-weight: bold;
  font-style: italic;
}

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($portal-theme);

@include mat-base-typography($custom-typography);
@include angular-material-typography($custom-typography);

.hint {
  font-weight: 100;
  font-size: 14px;
}
