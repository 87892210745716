/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Acme&family=Alfa+Slab+One&family=Amatic+SC:wght@700&family=Architects+Daughter&family=Barrio&family=Black+Ops+One&family=Bonbon&family=Bubblegum+Sans&family=Bungee&family=Bungee+Shade&family=Flavors&family=Freckle+Face&family=Fredoka+One&family=Henny+Penny&family=Indie+Flower&family=Lobster&family=Luckiest+Guy&family=Pacifico&family=Permanent+Marker&family=Press+Start+2P&family=Rammetto+One&family=RocknRoll+One&family=Special+Elite&family=UnifrakturMaguntia&family=Voltaire&family=Wendy+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Blaka&family=Boogaloo&family=Bowlby+One+SC&family=Calligraffitti&family=Chewy&family=Dekko&family=Erica+One&family=Fredericka+the+Great&family=Fredoka+One&family=Gochi+Hand&family=Love+Ya+Like+A+Sister&family=Marck+Script&family=Miltonian+Tattoo&family=Pangolin&family=Patrick+Hand+SC&family=Ribeye&family=Rock+Salt&family=Sedgwick+Ave&family=Ultra&family=Walter+Turncoat&display=swap&family=Montserrat");

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.toolbar-spacer {
  flex: 1 1 auto;
}
