@font-face {
  font-family: 'Blaka';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/blaka/v7/8vIG7w8722p_6kdr.ttf) format('truetype');
}
@font-face {
  font-family: 'Boogaloo';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/boogaloo/v23/kmK-Zq45GAvOdnaW6x1F.ttf) format('truetype');
}
@font-face {
  font-family: 'Bowlby One SC';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/bowlbyonesc/v25/DtVlJxerQqQm37tzN3wMug9Pzgg.ttf) format('truetype');
}
@font-face {
  font-family: 'Calligraffitti';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/calligraffitti/v19/46k2lbT3XjDVqJw3DCmCFjE0vnFZ.ttf) format('truetype');
}
@font-face {
  font-family: 'Chewy';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/chewy/v18/uK_94ruUb-k-wk5x.ttf) format('truetype');
}
@font-face {
  font-family: 'Dekko';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/dekko/v21/46khlb_wWjfSrttF.ttf) format('truetype');
}
@font-face {
  font-family: 'Erica One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ericaone/v27/WBLnrEXccV9VGrOKmGD1Ww.ttf) format('truetype');
}
@font-face {
  font-family: 'Fredericka the Great';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/frederickathegreat/v21/9Bt33CxNwt7aOctW2xjbCstzwVKsIBVV-9Sk.ttf) format('truetype');
}
@font-face {
  font-family: 'Fredoka One';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/fredokaone/v14/k3kUo8kEI-tA1RRcTZGmTmHB.ttf) format('truetype');
}
@font-face {
  font-family: 'Gochi Hand';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/gochihand/v23/hES06XlsOjtJsgCkx1PkTo4.ttf) format('truetype');
}
@font-face {
  font-family: 'Love Ya Like A Sister';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/loveyalikeasister/v22/R70EjzUBlOqPeouhFDfR80-0FhOqJubN-Be78g.ttf) format('truetype');
}
@font-face {
  font-family: 'Marck Script';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/marckscript/v20/nwpTtK2oNgBA3Or78gapdwuCzw.ttf) format('truetype');
}
@font-face {
  font-family: 'Miltonian Tattoo';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/miltoniantattoo/v32/EvOUzBRL0o0kCxF-lcMCQxlpVsA_FwM.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v29/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Ew-.ttf) format('truetype');
}
@font-face {
  font-family: 'Pangolin';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/pangolin/v11/cY9GfjGcW0FPpi-tWPfK.ttf) format('truetype');
}
@font-face {
  font-family: 'Patrick Hand SC';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/patrickhandsc/v15/0nkwC9f7MfsBiWcLtY65AWDK873ViQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Ribeye';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ribeye/v25/L0x8DFMxk1MP9R3RvA.ttf) format('truetype');
}
@font-face {
  font-family: 'Rock Salt';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/rocksalt/v22/MwQ0bhv11fWD6QsAVOZbsA.ttf) format('truetype');
}
@font-face {
  font-family: 'Sedgwick Ave';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/sedgwickave/v12/uK_04rKEYuguzAcSYRdWTJq8Xg.ttf) format('truetype');
}
@font-face {
  font-family: 'Ultra';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/ultra/v24/zOLy4prXmrtY-tT6.ttf) format('truetype');
}
@font-face {
  font-family: 'Walter Turncoat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/walterturncoat/v23/snfys0Gs98ln43n0d-14ULoToe67YA.ttf) format('truetype');
}
